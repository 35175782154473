import React from "react";
import { authRoles } from "../../auth";

export const AdminConfig = {
    auth: authRoles.admin,
    settings: {
        navbar: {
            display: true
        },
        toolbar: {
            display: true
        },
    },
    routes: [
        {
            path: '/admin/users',
            component: React.lazy(() => import('./pages/users/UsersApp')),
        },
        {
            path: '/admin/user/:ID',
            component: React.lazy(() => import('./pages/user/UserApp')),
        },
        {
            path: '/admin/user-security/:ID',
            component: React.lazy(() => import('./pages/user_security/UserSecurityApp')),
        },
    ]
};